#canvas-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  cursor: crosshair;

  width: 100%;
  height: 100%;
}
.set-default-btn {
  background-color: #488cff;
  color: #fcfcfc;
}
.remove-selected-area-btn {
  background: #dc3545;
  color: #fcfcfc;
}
.set-default-btn,
.remove-selected-area-btn {
  position: fixed;

  height: 30px !important;
  border: 1px solid transparent !important;
  border-radius: 15px !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;

  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  white-space: nowrap !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;

  padding: 0.375rem 0.75rem !important;
}
