/* tc- project's prefix*/
.tc-wrap {
  --cell-size: 34px;
  --toolbox-icon-size: 18px;
  --toolbox-padding: 6px;
  --toolbox-aiming-field-size: calc(var(--toolbox-icon-size) + 2 * var(--toolbox-padding));

  border-left: 0px;
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: var(--toolbox-icon-size);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: calc(100% - var(--cell-size)) var(--cell-size);
}
.tc-wrap--readonly {
  grid-template-columns: 100% var(--cell-size);
}
.tc-wrap svg {
  vertical-align: top;
  fill: var(--gray7);
}

/* changes table to read-only view in order to print */
@media print {
  .tc-wrap {
    grid-template-columns: 100% var(--cell-size);
  }
  .tc-wrap .tc-row::after {
    display: none;
  }
}
.tc-table {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  font-size: 1.4rem;
  border-top: 1px solid var(--gray4);
  line-height: 1.4;
  border-left: 1px solid var(--gray4);
}
.tc-table::after {
  position: absolute;
  content: "";
  width: calc(var(--cell-size));
  height: 100%;
  left: calc(-1 * var(--cell-size));
  top: 0;
}
.tc-table::before {
  position: absolute;
  content: "";
  width: 100%;
  height: var(--toolbox-aiming-field-size);
  top: calc(-1 * var(--toolbox-aiming-field-size));
  left: 0;
}
.tc-table--heading .tc-row:first-child {
  font-weight: 600;
  border-bottom: 2px solid var(--gray4);
}
.tc-table--heading .tc-row:first-child [contenteditable]:empty::before {
  content: attr(heading);
  color: var(--gray7);
}
.tc-table--heading .tc-row:first-child::after {
  bottom: -2px;
  border-bottom: 2px solid var(--gray4);
}
.tc-add-column,
.tc-add-row {
  display: flex;
  color: var(--gray7);
}
.tc-add-column {
  padding: 9px 0;
  justify-content: center;
  border-top: 1px solid var(--gray4);
}
.tc-add-row {
  height: var(--cell-size);
  align-items: center;
  padding-left: 12px;
  position: relative;
}
.tc-add-column,
.tc-add-row {
  transition: 0s;
  cursor: pointer;
  will-change: background-color;
}
.tc-add-column:hover,
.tc-add-row:hover {
  transition: background-color 0.1s ease;
  background-color: var(--gray1);
}
.tc-add-row {
  margin-top: 1px;
}
.tc-add-row:hover::before {
  transition: 0.1s;
  background-color: var(--gray1);
}
.tc-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  position: relative;
  border-bottom: 1px solid var(--gray4);
}
.tc-row::after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: var(--cell-size);
  height: 100%;
  bottom: -1px;
  right: calc(-1 * var(--cell-size));
  border-bottom: 1px solid var(--gray4);
}
.tc-row--selected {
  background: var(--gray1);
}
.tc-row--selected::after {
  background: var(--gray1);
}
.tc-cell {
  border-right: 1px solid var(--gray4);
  padding: 6px 12px 6px 12px;
  overflow: hidden;
  outline: none;
  line-break: normal;
}
.tc-cell--selected {
  background: var(--gray1);
}
.tc-wrap--readonly .tc-row::after {
  display: none;
}
.tc-toolbox {
  --toolbox-padding: 6px;
  --popover-margin: 30px;
  --toggler-click-zone-size: 30px;
  --toggler-dots-color: #7b7e89;
  --toggler-dots-color-hovered: #1d202b;

  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;

  will-change: left, opacity;
}
.tc-toolbox--column {
  top: calc(-1 * (var(--toggler-click-zone-size)));
  transform: translateX(calc(-1 * var(--toggler-click-zone-size) / 2));
  will-change: left, opacity;
}
.tc-toolbox--row {
  left: calc(-1 * var(--popover-margin));
  transform: translateY(calc(-1 * var(--toggler-click-zone-size) / 2));
  margin-top: -1px; /* because of top border */
  will-change: top, opacity;
}
.tc-toolbox--showed {
  opacity: 1;
}
.tc-toolbox .tc-popover {
  position: absolute;
  top: 0;
  left: var(--popover-margin);
}
.tc-toolbox__toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--toggler-click-zone-size);
  height: var(--toggler-click-zone-size);
  color: var(--toggler-dots-color);
  opacity: 0;
  transition: opacity 150ms ease;
  will-change: opacity;
  cursor: pointer;
}
.tc-toolbox__toggler:hover {
  color: var(--toggler-dots-color-hovered);
}
.tc-wrap:hover .tc-toolbox__toggler {
  opacity: 1;
}
.tc-settings .cdx-settings-button {
  width: 50%;
  height: 28px;
  margin: 0;
  border-radius: 4px;
}
.tc-settings .cdx-settings-button:hover {
  background-color: var(--gray3);
}
.tc-settings .cdx-settings-button:active {
  background-color: var(--gray2);
}

.tc-popover {
  width: 220px;
  height: 124px;
  background: #fff;
  box-shadow: var(--boxShadow2);
  border-radius: 10px;
  padding: 8px 0;
  display: none;
  will-change: opacity, transform;
}
.tc-popover--opened {
  display: block;
  -webkit-animation: menuShowing 100ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  animation: menuShowing 100ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}
.tc-popover__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
}
.tc-popover__item:hover {
  background: var(--gray3);
}
.tc-popover__item:active {
  background: var(--gray2);
}

.tc-popover__item-icon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.tc-popover__item-label {
  font-size: 1.3rem !important;
  line-height: 1.8rem;
  color: var(--gray15);
}

.cdx-settings-button--active svg path {
  fill: var(--blue8);
}

@-webkit-keyframes menuShowing {
  0% {
    opacity: 0;
    transform: translateY(-8px) scale(0.9);
  }
  70% {
    opacity: 1;
    transform: translateY(2px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes menuShowing {
  0% {
    opacity: 0;
    transform: translateY(-8px) scale(0.9);
  }
  70% {
    opacity: 1;
    transform: translateY(2px);
  }
  to {
    transform: translateY(0);
  }
}
