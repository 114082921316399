$hover-background-color: rgba(141, 153, 173, 0.2);

.list-tags-container {
  &.hover {
    background-color: var(--gray3);
  }

  *{
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
  }
}
