.cdx-checklist {
    --padding: 5px;
    --color-border: #d0d0d0;
    --color-border-hover: #b5b5b5;
    --color-bg-checked: #388ae5;
    --color-bg-checked-hover: #307cd1;
    --color-tick: #fff;

    &__item {
        display: flex;
        box-sizing: content-box;

        &-text {
            outline: none;
            flex-grow: 1;
            padding: var(--padding) 0;
        }

        &-checkbox {
            display: inline-block;
            flex-shrink: 0;
            position: relative;
            width: 20px;
            height: 20px;
            margin:  var(--padding);
            margin-left: 0;
            margin-right: 7px;
            border-radius: 50%;
            border: 1px solid var(--color-border);
            background: #fff;
            cursor: pointer;
            user-select: none;

            &:hover {
                border-color: var(--color-border-hover);
            }

            &::after {
                position: absolute;
                top: 6px;
                left: 5px;
                width: 9px;
                height: 4px;
                border: 2px solid var(--color-tick);
                border-top: none;
                border-right: none;
                background: transparent;
                content: '';
                opacity: 0;
                transform: rotate(-45deg);
            }
        }

        &--checked {
            ^&-checkbox {
                background: var(--color-bg-checked);
                border-color: var(--color-bg-checked);

                &:hover {
                    background: var(--color-bg-checked-hover);
                }

                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
