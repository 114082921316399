.tooltip-inner {
  background-color: var(--gray17) !important;
  font-size: 1rem !important;
  padding: 4px 6px !important;
}
.swal-custom-toast-container {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
.swal-custom-toast-container .swal2-popup {
  padding: 0;
  margin: 0;
  background-color: var(--gray13);
  box-shadow: none;
}

.swal-custom-toast-container .swal2-content {
  padding: 0;
  border-radius: 8px;
}
