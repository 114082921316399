.ce-code__textarea {
  min-height: 150px;
  font-family:
    Menlo,
    Monaco,
    Consolas,
    Courier New,
    monospace;
  color: var(--gray15);
  line-height: 1.6;
  font-size: 1.3rem;
  background: var(--gray1);
  box-shadow: none;
  white-space: inherit;
  word-wrap: normal;
  overflow-x: auto;
  resize: vertical;
  outline: 1px solid var(--gray4);
  border: none;
  border-radius: 4px;
}

.block-hover-button__container {
  position: absolute;
  top: 13px;
  right: 7px;
  align-items: center;
  justify-content: center;
  display: none;
}

.block-hover-button__item {
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  /* e of reset button  */

  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 37, 41, 0.8);
  cursor: pointer;
  border-right: 1px solid var(--gray9);
}

.block-hover-button__item:focus {
  outline: 0;
}

.block-hover-button__item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.block-hover-button__item:last-child {
  border-right: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.block-hover-button__item:hover {
  background-color: rgba(33, 37, 41, 0.9);
}

.block-hover-button__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-hover-button__icon-container svg {
  fill: #fff;
}

.block-hover-button__container.block-hover-button__shown {
  display: flex;
}
