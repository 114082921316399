.cdx-unordred-list-outer-wrapper {
  max-width: 100%;
  padding: 0.4em 0;
  line-height: 1.6;
}

.cdx-unordred-list-inner-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  color: inherit;
  fill: inherit;
}

.cdx-unordred-list__pseudo-selection {
  background: transparent;
  margin-right: 8px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.cdx-blounordredck-list-settings {
  display: flex;
}
.cdx-unordred-list-settings .cdx-settings-button {
  width: 50%;
}

.cdx-unordred-list___input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cdx-unordred-list__pseudo-before {
  line-height: 1.2;
  font-size: 1.8rem;
}

.cdx-unordred-list__pseudo-before::before {
  line-height: 1.2;
  content: attr(data-before);
}

.cdx-unordred-list__input {
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  text-align: left;
  outline: none;
}

@media (max-width: 799px) {
  .cdx-unordred-list-outer-wrapper {
    padding: 0;
  }
}
