.link-tool {
  position: relative;
}
@media print {
  .link-tool {
    break-inside: avoid;
  }
}

.link-tool__input {
  padding-left: 38px;
  border-radius: 0;
  background-image: url("/public/src/icons/icon_link_20.svg");
  background-size: 13px 14px;
  background-repeat: no-repeat;
  background-position: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: none;
  border: 1px solid var(--gray4);
  border-radius: 4px;
}

.link-tool__input-holder {
  position: relative;
}

.link-tool__input-holder--error .link-tool__input {
  border-color: var(--red7);
}

.link-tool__input[contentEditable="true"][data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  color: var(--gray7);
  font-weight: normal;
  opacity: 0;
  cursor: text;
  pointer-events: none;
}
.link-tool__input[contentEditable="true"][data-placeholder]:empty::before {
  opacity: 1;
}
.link-tool__input[contentEditable="true"][data-placeholder]:empty:focus::before {
  opacity: 0;
}

.link-tool__progress {
  position: absolute;
  height: 100%;
  width: 0;
  background-color: var(--gray2);
  z-index: -1;
}

.link-tool__progress--loading {
  -webkit-animation: progress 1000ms ease-in;
  animation: progress 1000ms ease-in;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.link-tool__progress--loaded {
  width: 100%;
}

.link-tool__content {
  display: block;
  color: initial !important;
  text-decoration: none !important;
  cursor: pointer;
}

.link-tool__text-container {
  padding: 16px 25px 20px;
}

.text-link-tool__text-container {
  min-height: 116px;
  padding: 25px;
}

.link-tool__content::after {
  content: "";
  clear: both;
  display: table;
}

.link-tool__content--rendered {
  background: #fff;
  outline: 1px solid var(--gray4);
  will-change: filter;
  -webkit-animation: link-in 450ms 1 cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: link-in 450ms 1 cubic-bezier(0.215, 0.61, 0.355, 1);
}

.link-tool__content--rendered:hover {
  outline: 2px solid var(--blue7);
}
.ce-block--focused .link-tool .link-tool__content--rendered {
  outline: 2px solid var(--blue7);
}

.link-tool__image-container {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  float: right;
  margin-left: 20px;
}

.link-tool__image-container img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}

.link-tool__large-image-container {
  float: none;
  height: auto;
  width: 100%;
  border-radius: 0;
  margin-left: 0;
}

.link-tool__large-image-container img {
  width: 100%;
  max-height: 320px;
  border-radius: 0px;
  object-fit: cover;
}

.link-tool__title {
  font-size: 1.7rem !important;
  font-weight: 700;
  line-height: 2.6rem;
  font-style: normal;
  margin: 0 0 8px 0;
  color: var(--gray17);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

.text-link-tool__title {
  margin: 0 0 10px 0;
}

.link-tool > .link-tool__title__anchorfirst-child {
  margin-top: 25px;
}

.link-tool__description {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.3rem;
  margin: 0 0 16px 0;
  color: var(--gray17);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

.text-link-tool__description {
  margin: 0 0 20px 0;
  font-size: 1.7rem;
  line-height: 2.6rem;
}

.link-tool__anchor {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem !important;
  line-height: 2.3rem;
  color: var(--gray7) !important;
  border: 0 !important;
  padding: 0 !important;
}

.text-link-tool__anchor {
  font-size: 1.7rem !important;
  line-height: 2.6rem;
}
.codex-editor--narrow .link-tool__image {
  display: none;
}

@media screen and (max-width: 799px) {
  .link-tool__text-container {
    padding: 12px 20px 16px;
  }

  .text-link-tool__text-container {
    min-height: 108px;
    padding: 20px;
  }

  .link-tool__text-container.image-view {
    padding: 12px 20px 16px;
  }

  .link-tool__large-image-container img {
    max-height: 180px;
  }

  .link-tool__title,
  .text-link-tool__title {
    margin: 0 0 4px 0;
    font-size: 1.5rem !important;
    line-height: 2.3rem;
  }

  .link-tool__description {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin: 0 0 12px 0;
  }

  .text-link-tool__description {
    margin: 0 0 16px 0;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .link-tool__anchor,
  .text-link-tool__anchor {
    font-size: 1.3rem !important;
    line-height: 1.8rem;
  }
}

.link-tool-settings .cdx-settings-button {
  width: 50%;
  height: 28px;
  margin: 0;
  border-radius: 4px;
}
.link-tool-settings .cdx-settings-button:hover {
  background-color: var(--gray3);
}
.link-tool-settings .cdx-settings-button:active {
  background-color: var(--gray2);
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 85%;
  }
}
@keyframes link-in {
  from {
    filter: blur(5px);
  }
  to {
    filter: none;
  }
}
@keyframes link-in {
  from {
    filter: blur(5px);
  }
  to {
    filter: none;
  }
}
