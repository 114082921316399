$bg-color-black: #1f1f1f;
$bg-color-dark: #3d3d3d;
$bg-color-dark-gray: #dcdcdc;
$bg-color-light-gray: #f7f7f9;
$bg-color-white: #ffffff;
$bg-color-blue: #488cff;
$color-white: #f3f3f3 !important;
$color-light-gray: #838383;
$border-radius: 5px !important;
$font-size-small: 1.4rem !important;

$list-x-padding: 0 15px;

.deleted-documents-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $bg-color-white;
  padding: 20px 27px;
  border-radius: $border-radius;

  width: 335px;
  height: 400px;
  max-height: 90%;
  overflow: auto;

  .document-list-container {
    font-size: $font-size-small;
    text-align: left;
    color: $color-light-gray;

    .deleted-document-loader-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .document-list {
      overflow-y: auto;
      height: 350px;
      margin-top: 10px;

      .document-list-title {
        border-bottom: 1px solid $bg-color-dark-gray;
        padding: 5px 0;
      }

      ul {
        padding: 0;
        padding-top: 10px;

        .document-list-item {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;
          list-style-type: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          border-radius: 4px;
          img {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          .document-list-item-title {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            display: flex;
            align-items: center;

            .document-list-item-icon {
              margin-right: 4px;
            }
          }

          .document-list-item-button {
            display: flex;
            align-items: center;
            padding-right: 5px;
            cursor: pointer;

            .document-list-item-button-icon {
              margin-left: 10px;
              width: 20px;
              height: 20px;
            }
          }

          &:not(.no-hover):hover {
            background-color: var(--gray2);
          }
        }
      }
    }
  }
}

.delete-note-permanently-noti-modal {
  z-index: 10000;
}
