.audio-loader-placeholder {
  padding: 14px 0px;
  width: 100%;
  background: var(--gray13);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-header {
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  color: #ffffff;
}

.countdown-red {
  color: #ff0000;
}

.loader-body {
  font-style: normal;
  font-weight: 300;
  font-size: 1.9rem;
  color: #ffffff;
}

.loader-body-additional-text {
  color: var(--gray9);
}
