$bg-color-black: #1f1f1f;
$bg-color-dark: #3d3d3d;
$bg-color-dark-gray: #dcdcdc;
$bg-color-light-gray: #f7f7f9;
$bg-color-white: #ffffff;
$bg-color-blue: #488cff;
$color-white: #f3f3f3 !important;
$color-light-gray: #838383;
$border-radius: 5px !important;
$font-size-small: 1.4rem !important;

$list-x-padding: 0 15px;

.search-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $bg-color-white;
  padding: 22px 27px;
  border-radius: $border-radius;

  width: 335px;
  max-height: 90%;
  overflow: auto;

  .search-input-container {
    .search-input {
      width: 100%;

      background: #f2f2f2;
      border-radius: 44px;
    }
  }

  .document-list-container {
    font-size: $font-size-small;
    text-align: left;
    color: $color-light-gray;
    margin-top: 20px;

    .document-list-title {
      border-bottom: 1px solid $bg-color-dark-gray;
      padding: 7px 0;
    }

    .document-list {
      overflow-y: auto;
      height: 230px;
      margin-top: 10px;

      ul {
        padding: 0;

        .document-list-item {
          padding: 5px 0;
          list-style-type: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: flex;
          align-items: center;
          border-radius: 4px;

          .item-icon {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }

          &:not(.no-hover):hover {
            background-color: var(--gray2);
            cursor: pointer;
          }
        }
      }
    }
  }
}
