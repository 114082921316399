$bg-color-gray: #c4c4c4;

.desktop-document-container {
  height: 100%;
  width: 100%;
}
.stt-desktop-document-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.stt-desktop-transcript-container {
  height: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
}

.vdocs-container {
  height: 100%;
  min-height: -webkit-fill-available;

  .split-wrapper {
    height: 100%;
    .gutter {
      background-color: var(--gray7);
      &:hover {
        cursor: col-resize;
        background-color: var(--blue8);
      }
    }
    .dark-gutter {
      background-color: var(--gray15);
    }
  }

  .extension-videoplayer-transcript-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .vertical-vdocs-container {
    height: 100%;
    min-height: -webkit-fill-available;
    /* padding-top should be set by header's height */
    padding-top: 52px;

    display: flex;
    flex-direction: column;

    .vertical-document-container {
      height: 100px;
      flex: 1 1 auto;
    }
  }
}
