.pseudo-typing-en::after {
  content: " " url("/public/src/assets/icon_recording_28.svg") " Typing..";
  display: inline;
  font-size: 1.3rem;
  color: var(--gray9);
}

.pseudo-typing-ko::after {
  content: " " url("/public/src/assets/icon_recording_28.svg") " 입력 중..";
  display: inline;
  font-size: 1.3rem;
  color: var(--gray9);
}

.pseudo-waiting-en::after {
  content: " " url("/public/src/assets/icon_recording_28.svg") " Waiting..";
  display: inline;
  font-size: 1.3rem;
  color: var(--gray9);
}

.pseudo-waiting-ko::after {
  content: " " url("/public/src/assets/icon_recording_28.svg") " 대기 중..";
  display: inline;
  font-size: 1.3rem;
  color: var(--gray9);
}
