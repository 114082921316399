.loader-placeholder {
  padding-top: 20% !important;
  padding-bottom: 20% !important;
  width: 100%;
  max-height: 375px;
  background: #292929;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-header {
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  color: #ffffff;
}

.countdown-red {
  color: #ff0000;
}

.loader-body {
  font-style: normal;
  font-weight: 300;
  font-size: 1.9rem;
  color: #ffffff;
}

.loader-body-additional-text {
  color: #646464;
}
