.picker_wrapper.popup {
  z-index: 99;
  width: 170px;
  margin: 0;
  box-shadow: 0 0 10px 1px #eaeaea;
  background: #ffffff;
}

.picker_arrow {
  display: none;
}

.layout_default .picker_slider,
.layout_default .picker_selector {
  padding: 5px;
}

.picker_wrapper.popup {
  z-index: 99;
  width: 170px;
  margin: 0;
  box-shadow: 0 0 10px 1px #eaeaea;
  background: #ffffff;
}

.picker_arrow {
  display: none;
}

.layout_default .picker_slider,
.layout_default .picker_selector {
  padding: 5px;
}

.colorPlugin.ce-inline-tool {
  width: 44px;
  border-left: 1px solid var(--gray4);
  padding: 0 !important;
}

.colorPlugin.ce-inline-tool--active svg {
  fill: #3c99ff;
}

#color-left-btn {
  height: 35px;
  width: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

#color-left-btn:hover {
  border-radius: 5px 0 0 5px;
  background: rgba(203, 203, 203, 0.49);
}

#color-text {
  padding: 0 4px;
}

#color-btn-text {
  height: 15px;
}
