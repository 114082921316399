.ce-audio-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--gray13);
  border-radius: 4px;
  padding: 10px 20px;
  margin: 10px 0px;
}
