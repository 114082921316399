$bg-color-black: #1f1f1f;
$bg-color-dark: #3d3d3d;
$bg-color-dark-gray: #dcdcdc;
$bg-color-light-gray: #f7f7f9;
$bg-color-white: #ffffff;
$bg-color-blue: #488cff;
$color-black: #616161;
$color-white: #f3f3f3 !important;
$color-light-gray: #838383;
$list-item-top-margin: 5px;
$list-item-y-padding: 3px;

.document-item-container {
  margin-top: $list-item-top-margin;
  padding: $list-item-y-padding;
  position: relative;
  cursor: pointer;
  border-radius: 4px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .option-edit-icon-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 0px;
    transform: translate(0, -50%);
  }

  .document-icon,
  .option-edit-icon {
    width: 24px;
    height: 24px;
  }

  .document-title {
    width: 5px;
  }

  .option-edit-icon {
    display: none;

    @media screen and (max-width: 799px) {
      display: inline;
    }
  }
  &:hover .option-edit-icon {
    display: inline;
  }

  &.active {
    background-color: var(--gray4);
  }
}

.option-edit-dropdown-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 3;

  .option-edit-dropdown {
    position: absolute;
    left: 125px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    border-radius: 5px;
    background-color: $bg-color-white;

    padding: 5px 0;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: 8px 30px 8px 12px;
        cursor: pointer;
        display: flex;
        align-items: center;

        .option-edit-icon {
          margin-right: 8px;
        }
      }

      li:hover {
        background-color: $bg-color-light-gray;
      }
    }
  }
}
