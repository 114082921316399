/* S of Pretendard */
@font-face {
  font-family: "SlidTextStyle";
  font-style: normal;
  font-weight: 400;
  src: local("Pretendard-Regular"), url("./fonts/Pretendard/Pretendard-Regular.woff2") format("woff2"), url("./fonts/Pretendard/Pretendard-Regular.woff") format("woff"),
    url("./fonts/Pretendard/Pretendard-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SlidTextStyle";
  font-style: normal;
  font-weight: 500;
  src: local("Pretendard-Medium"), url("./fonts/Pretendard/Pretendard-Medium.woff2") format("woff2"), url("./fonts/Pretendard/Pretendard-Medium.woff") format("woff"),
    url("./fonts/Pretendard/Pretendard-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SlidTextStyle";
  font-style: normal;
  font-weight: 700;

  src: local("Pretendard-Bold"), url("./fonts/Pretendard/Pretendard-Bold.woff2") format("woff2"), url("./fonts/Pretendard/Pretendard-Bold.woff") format("woff"),
    url("./fonts/Pretendard/Pretendard-Bold.ttf") format("truetype");
  font-display: swap;
}
/* E of Pretendard */

/* for landing(pricing) page */

/* S of Poppins */
@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Poppins/Poppins-Regular.woff2") format("woff2"), url("./fonts/Poppins/Poppins-Regular.woff") format("woff"), url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-display: optional;
}

@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Poppins/Poppins-Bold.woff2") format("woff2"), url("./fonts/Poppins/Poppins-Bold.woff") format("woff"), url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-display: optional;
}

/* S of Pretendard */
/* 유니코드 참고 */
/* https://namu.wiki/w/%EC%9C%A0%EB%8B%88%EC%BD%94%EB%93%9C */
@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 400;
  src: local("Pretendard-Regular"), url("./fonts/Pretendard/Pretendard-Regular.woff2") format("woff2"), url("./fonts/Pretendard/Pretendard-Regular.woff") format("woff"),
    url("./fonts/Pretendard/Pretendard-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 500;
  src: local("Pretendard-Medium"), url("./fonts/Pretendard/Pretendard-Medium.woff2") format("woff2"), url("./fonts/Pretendard/Pretendard-Medium.woff") format("woff"),
    url("./fonts/Pretendard/Pretendard-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SlidLandingTextStyle";
  font-style: normal;
  font-weight: 700;

  src: local("Pretendard-Bold"), url("./fonts/Pretendard/Pretendard-Bold.woff2") format("woff2"), url("./fonts/Pretendard/Pretendard-Bold.woff") format("woff"),
    url("./fonts/Pretendard/Pretendard-Bold.ttf") format("truetype");
  font-display: swap;
}
/* E of Pretendard */
