.ce-image-loader__input {
  display: none;
}

.ce-image-loader__button {
  width: 100%;
}

.ce-image-loader__disabled {
  background-color: var(--gray1);
  color: var(--gray7);
}

.cdx-button {
  outline: 1px solid var(--gray4);
  border: none;
  box-shadow: none;
  border-radius: 4px;
  color: var(--gray7);
  font-size: 1.6rem;
  line-height: 2rem;
}

.cdx-button:hover {
  box-shadow: none;
  background-color: var(--gray1);
}

.cdx-button:active {
  outline: 2px solid var(--blue7);
}
