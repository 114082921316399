$bg-color-black: #1f1f1f;
$bg-color-dark: #3d3d3d;
$bg-color-dark-gray: #dcdcdc;
$bg-color-light-gray: #f7f7f9;
$bg-color-white: #ffffff;
$bg-color-blue: #488cff;
$color-black: #616161;
$color-white: #f3f3f3 !important;
$color-light-gray: #838383;
$border-radius: 5px !important;
$font-size-small: 1.4rem !important;

$list-x-padding: 0 15px;

.list-view-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid var(--gray5);
  background-color: var(--gray1);

  .close-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $list-x-padding;
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    .close-btn {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .home-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $list-x-padding;
    padding-top: 15.5px;
    padding-bottom: 15.5px;

    border-bottom: 1px solid #e3e3e3;

    a {
      display: flex;
      align-items: center;
      color: $color-light-gray;

      .home-btn {
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }
    }
  }

  .docs-list-container {
    padding: $list-x-padding;
    padding-top: 15.5px;
    padding-bottom: 100px;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;

    .docs-list-title {
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      .search-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .docs-list {
      margin-top: 20px;
      margin-bottom: 110px;
    }
  }

  .docs-list-button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid var(--gray5);
    border-right: 1px solid var(--gray5);
    background-color: var(--gray1);
    position: fixed;
    bottom: 0;
    width: 300px;

    .trash-can-container {
      display: flex;
      width: 100%;
      cursor: pointer;

      .trash-can-body {
        padding: 15px;
        display: flex;
        align-items: center;
        .trash-can-icon {
          height: 24px;
          width: 24px;
          margin-right: 5px;
        }
      }
    }

    .item-add-btn-container {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: 1px solid var(--gray5);

      .item-add-btn {
        padding-top: 15.5px;
        padding-bottom: 15.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        color: var(--gray9);
        cursor: pointer;

        &:first-of-type {
          border-right: 0.5px solid var(--gray5);
        }

        .item-icon {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }
      }
    }
  }
}
