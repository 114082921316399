.cdx-checklist__item {
  display: flex;
  box-sizing: content-box;
}
.cdx-checklist__item-text {
  outline: none;
  flex-grow: 1;
  max-width: 100%;
}
.cdx-checklist__item-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin: 4px 8px 4px 0;
  border: 1px solid var(--gray5);
  background: #fff;
  cursor: pointer;
  user-select: none;
  padding: 0 !important;
}

.cdx-checklist__item-checkbox svg {
  fill: none;
}
.cdx-checklist__item-checkbox:hover {
  border-color: var(--gray7);
}
.cdx-checklist__item-checkbox::after {
  opacity: 0;
}
.cdx-checklist__item--checked .cdx-checklist__item-checkbox {
  background: var(--blue8);
  border-color: var(--blue9);
}
.cdx-checklist__item--checked .cdx-checklist__item-checkbox:hover {
  background: var(--blue9);
}
.cdx-checklist__item--checked .cdx-checklist__item-checkbox::after {
  opacity: 1;
}
