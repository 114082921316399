.CodeMirror * {
  font-family: "Fira Code", sans-serif;
  font-size: 1.6rem;
}

.CodeMirror {
  border: 1px solid var(--gray4);
  border-top: none;
  z-index: 0;
  border-radius: 0 0 4px 4px;
}

.cdx-codemirror__selectwrapper__Dark {
  display: flex;
  justify-content: space-between;
  background-color: var(--gray15);
  border: 1px solid 282a36;
  border-bottom: none;
  padding: 7px 12px;
  padding-bottom: 10px;
  color: #667e7f;
  border-radius: 4px 4px 0 0;
}

.cdx-codemirror__selectwrapper__Light {
  display: flex;
  justify-content: space-between;
  background-color: var(--gray1);
  border: 1px solid var(--gray4);
  border-bottom: none;
  padding: 7px 12px;
  padding-bottom: 10px;
  color: var(--gray7);
  border-radius: 4px 4px 0 0;
}

.cdx-codemirror__language {
  cursor: pointer;
  padding-right: 2em;
  border: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  font-size: 1.6rem;
  color: inherit;
}

.cdx-codemirror__language:focus {
  border: 0;
  outline: 0;
}

.cdx-codemirror__language:active {
  border: 0;
  outline: 0;
}

.cdx-codemirror__themeSelect {
  cursor: pointer;
  border: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  font-size: 1.6rem;
  direction: rtl;
  color: inherit;
}

.cdx-codemirror__themeSelect:focus {
  border: 0;
  outline: 0;
}

.cdx-codemirror__themeSelect:active {
  border: 0;
  outline: 0;
}

.CodeMirror-gutters {
  border-right: 1px solid var(--gray4);
  background-color: var(--gray1);
}

.CodeMirror-linenumber {
  color: var(--gray7);
}

.cm-s-dracula .CodeMirror-gutters {
  background-color: var(--gray15) !important;
  color: var(--gray9);
  border-right: 1px solid var(--gray13);
}

.cm-s-dracula.CodeMirror {
  background-color: var(--gray17) !important;
}
