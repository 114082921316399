#root,
body,
html,
.App {
  height: 100%;
  min-height: -webkit-fill-available;
  scroll-behavior: smooth;
}

.btn:focus {
  box-shadow: none;
}

.App-Component.error-container {
  font-size: 1em;
  height: 100vh;
}

.App-Component.error-container .loading-container,
.App-Component.error-container .loading-content-container,
.App-Component.error-container .loading-sample-container {
  height: 100vh;
}

.App-Component.error-container .error-content-container {
  font-size: 1.5em;
}

.App-Component.error-container .loading-sample-container .text-muted {
  font-size: 0.8em;
}

.App-Component.error-container .logo {
  height: 60px;
}

.App-Component.error-container .logo-text {
  width: 100px;
}

.App-Component.error-container .slid-sample-gif {
  width: 600px;
}

.main-loading-container {
  height: 100vh;
}

.main-loading-container .logo {
  height: 60px;
}

.main-loading-container .logo-text {
  width: 100px;
}

.pointer {
  cursor: pointer;
}

div[data-tippy-root] {
  z-index: 999999999 !important;
}
