.video-block {
  outline: none;
}

.video-block.height-placeholder {
  padding-top: 56.25% !important;
  outline: 1px solid var(--gray4);
  border-radius: 5px;
}

.video-block video {
  outline: 1px solid var(--gray4);
  background-color: var(--gray1);
  object-fit: contain;
  width: 100%;
  /* max-height: 475px; */
  max-height: 375px;
}

@media print {
  .video-block video {
    display: none;
    max-height: unset;
    border: unset;
    background: unset;
    max-height: 150mm;
  }

  @media (orientation: landscape) {
    .video-block video {
      max-height: 180mm;
    }
  }
}
