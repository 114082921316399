.math-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.math-preview {
  min-height: 50px;
  width: 100%;
  padding: 10px;
  outline: 1px solid var(--gray4);
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
}

.math-preview {
  margin: 10px 0;
  overflow-x: auto;
}

.math-preview::-webkit-scrollbar {
  height: 7px;
}
.math-preview::-webkit-scrollbar-thumb {
  background-color: var(--gray5);
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.math-input:focus-visible {
  border-radius: 4px;
  outline: 2px solid var(--blue7);
}
.math-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: 2px solid var(--blue7);
  background: none;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 0;
  color: var(--gray15);
}

.math-input-hide {
  display: none !important;
}

.errorMessage {
  color: red;
}
