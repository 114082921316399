.break-line {
  outline: none;
  height: 25px;
  display: flex;
  align-content: center;
}

.break-line__divider {
  height: 0px;
  width: 100%;
  padding: 0;
  border-top: 2px solid var(--gray4);
  align-self: center;
  display: block;
}
